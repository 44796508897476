<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card>
            <user-component :user_model="createUserModel" :roles="roles" :merchants="merchants" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid"
                color="success"
                @click="saveUserData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>

      <v-overlay
          :absolute="absolute"
          :value="overlay"
      >
        <v-card color="white">
          <v-card-text>
            <overlay-img></overlay-img>
          </v-card-text>
          </v-card>
      </v-overlay>

    </v-row>
  </v-container>
</template>

<script>
import RowHeader from "@/components/content/RowHeader";
import OverlayImg from "@/components/content/OverlayImg";
import UserComponent from '../../../components/page/users/UserComponent';
import {CreateUserModel, RoleUserList} from './UserModel';
import {PostRequest, GetRequest, RequestToken} from "../../../store/services/users";

export default {
  name: 'UserNew',
  components: {
    RowHeader,
    OverlayImg,
    UserComponent,
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Create User'},
    roles: new RoleUserList(),
    createUserModel: new CreateUserModel(),
    valid:true,
    absolute: false,
    overlay: false,
    merchants: [],
  }),
  async mounted() {
    if (this.$store.getters.currentUser.role.level > 1) {
      this.roles = this.roles.filter(val => {
        return val.level !== 1;
      });
    }
    if (!this.$store.getters.isLoggedIn) {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    }
    await this.getMerchantData();
    console.log('view page admin user new');
  },
  methods : {
    async getMerchantData() {
      this.loading = await this.$store.dispatch('req_data')
      try{
        let response = await GetRequest('merchants');
        this.merchants = response.data;
        this.loading = await this.$store.dispatch('req_success');
        return true;
      }catch(err){
        this.loading = await this.$store.dispatch('req_failed');
        if (err === 'Token expired') {
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            await this.getMerchantData();
          }
        } else {
          alert('get merchant data failed ' + err.message);
        }

        return false;
      }
    },
    async saveUserData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        await PostRequest('users', this.createUserModel);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        alert('Create user data success');
        await this.$router.push({name: 'User'});
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        if (e === 'Token expired') {
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            await this.saveUserData();
          }
        } else {
          alert('Create user data failed ' + e.message);
        }
      }
    },
    async refreshToken() {
      try {
        await RequestToken();
        return true;
      } catch (e) {
        alert('error load refresh token');
        return false;
      }
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="user_model.full_name"
            label="Name"
            prepend-inner-icon="mdi-account"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="user_model.email"
            sm="12"
            cols="6"
            label="Email"
            prepend-inner-icon="mdi-email"
            :rules="[emailRules.required, emailRules.email]"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="user_model.phone_number"
            sm="12"
            cols="6"
            label="Phone Number"
            prepend-inner-icon="mdi-cellphone"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="user_model.identity_id"
            sm="12"
            cols="6"
            label="Identity Number"
            prepend-inner-icon="mdi-account-box"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
        sm="6"
        cols="12"
        v-if="$store.getters.currentUser.role.level === 1"
      >
        <v-autocomplete
            dense
            v-model="user_model.bank_code"
            label="Bank Code"
            :items="merchants"
            item-text="name"
            item-value="code"
            clearable
            :rules="[v => !!v || 'Merchant must filled']"
        ></v-autocomplete>
      </v-col>
      <v-col sm="6" cols="12">
        <v-autocomplete
            dense
            v-model="user_model.role"
            label="Role"
            :items="roles"
            item-text="name"
            item-value="code"
            clearable
            :rules="[v => !!v || 'Role must filled']"
            prepend-inner-icon="mdi-account-multiple"
            return-object
        ></v-autocomplete>
      </v-col>
      <template v-if="!update">
        <v-col
            sm="6"
            cols="12"
        >
          <v-text-field
              dense
              v-model="user_model.password"
              sm="12"
              cols="6"
              label="Password"
              prepend-inner-icon="mdi-lock"
              type="password"
              :rules="passwordRules"
          ></v-text-field>
        </v-col>
        <v-col
            sm="6"
            cols="12"
        >
          <v-text-field
              dense
              v-model="confirm_password"
              sm="12"
              cols="6"
              label="Confirm Password"
              type="password"
              prepend-inner-icon="mdi-lock"
              :rules="[passwordConfirmationRule]"
          ></v-text-field>
        </v-col>
      </template>
      <template v-else>
        <v-col
          sm="6"
          cols="12"
        >
          <v-text-field
            dense
            v-model="user_model.current_balance"
            sm="12"
            cols="6"
            type="number"
            label="Current Balance"
            v-if="$store.getters.currentUser.role.level === 1"
          ></v-text-field>
        </v-col>
        <v-col
            sm="6"
            cols="12"
        >
          &nbsp;
        </v-col>
        <v-col
            sm="4"
            cols="12"
        >
          <v-divider></v-divider>
        </v-col>
        <v-col
            sm="4"
            cols="12"
            align="center"
        >
          <div style="font-weight:bold;font-size:15px;top:-10px;position:relative;" class="hidden-xs-only">
            Fill password if want to change password
          </div>
          <div style="font-weight: bold; font-size: 15px;" class="d-sm-none">
            Fill password if want to change password
          </div>
        </v-col>
        <v-col
            sm="4"
            cols="12"
        >
          <v-divider></v-divider>
        </v-col>
        <v-col
            sm="6"
            cols="12"
        >
          <v-text-field
              dense
              v-model="user_model.password"
              sm="12"
              cols="6"
              label="Password"
              prepend-inner-icon="mdi-lock"
              type="password"
          ></v-text-field>
        </v-col>
        <v-col
            sm="6"
            cols="12"
        >
          <v-text-field
              dense
              v-model="confirm_password"
              sm="12"
              cols="6"
              label="Confirm Password"
              type="password"
              prepend-inner-icon="mdi-lock"
          ></v-text-field>
        </v-col>
      </template>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "UserComponent",
  data: () => {
    return {
      confirm_password: '',
      RequiredRules: [
        v => !!v || 'Field cannot empty',
      ],
      emailRules: {
        required: value => !!value || "Email required",
        email: value => {
          const patern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
          return patern.test(value) || "Invalid Email"
        },
      },
      passwordRules: [
        (value) => !!value || 'Field cannot empty.',
        (value) => (value && value.length >= 6) || 'minimum 6 characters.',
      ],
    }
  },
  props: {
    user_model: Object,
    roles: [],
    merchants: [],
    update: String
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.user_model.password === this.confirm_password) || 'Password must match'
    }
  },
  mounted() {
    // console.log('user_model', this.user_model);
    // console.log('roles', this.roles);
    // console.log('update', this.update);
  }
}
</script>

<style scoped>

</style>

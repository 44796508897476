export function UserData() {
  return {
    _id: '',
    active: 0,
    full_name: '',
    email: '',
    phone_number: '',
    identity_id: '',
    password: '',
    current_balance: 0,
    role: {
      code: '',
      level: 0,
      name: '',
    },
    bank_code: '',
    created_at: '',
    created_by: '',
  }
}

export function CreateUserModel() {
  return {
    full_name: '',
    email: '',
    phone_number: '',
    identity_id: '',
    password: '',
    role: RoleUserModel,
    bank_code: '',
  }
}

export function RoleUserModel() {
  return {
    code: '',
    level: 0,
    name: '',
  }
}

export function RoleUserList() {
  return [
    {
      code: 'superadmin',
      level: 1,
      name: 'Super Admin',
    },
    {
      code: 'admin',
      level: 2,
      name: 'Admin',
    },
    {
      code: 'locket',
      level: 3,
      name: 'Locket',
    },
  ]
}
